/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionInfoDto
 */
export interface ActionInfoDto {
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfoDto
     */
    subscribedAndHasOrders: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfoDto
     */
    subscribedAndHasOrders8March: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfoDto
     */
    subscribedAndHasOrdersBetween1And7March: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfoDto
     */
    subscribed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfoDto
     */
    participant: boolean;
}


export function ActionInfoDtoFromJSON(json: any): ActionInfoDto {
    return ActionInfoDtoFromJSONTyped(json, false);
}

export function ActionInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscribedAndHasOrders': json['subscribedAndHasOrders'],
        'subscribedAndHasOrders8March': json['subscribedAndHasOrders8March'],
        'subscribedAndHasOrdersBetween1And7March': json['subscribedAndHasOrdersBetween1And7March'],
        'subscribed': json['subscribed'],
        'participant': json['participant'],
    };
}

export function ActionInfoDtoToJSON(value?: ActionInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscribedAndHasOrders': value.subscribedAndHasOrders,
        'subscribedAndHasOrders8March': value.subscribedAndHasOrders8March,
        'subscribedAndHasOrdersBetween1And7March': value.subscribedAndHasOrdersBetween1And7March,
        'subscribed': value.subscribed,
        'participant': value.participant,
    };
}

