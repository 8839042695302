/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderReviewStatus,
    CustomerOrderReviewStatusFromJSON,
    CustomerOrderReviewStatusFromJSONTyped,
    CustomerOrderReviewStatusToJSON,
} from './CustomerOrderReviewStatus';
import {
    ImageDto,
    ImageDtoFromJSON,
    ImageDtoFromJSONTyped,
    ImageDtoToJSON,
} from './ImageDto';

/**
 * 
 * @export
 * @interface CustomerOrderReviewDto
 */
export interface CustomerOrderReviewDto {
    /**
     * 
     * @type {CustomerOrderReviewStatus}
     * @memberof CustomerOrderReviewDto
     */
    status: CustomerOrderReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOrderReviewDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOrderReviewDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    advantages: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    disadvantages: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    comment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerOrderReviewDto
     */
    imageIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderReviewDto
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    customerOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderReviewDto
     */
    spuId: string;
    /**
     * 
     * @type {Array<ImageDto>}
     * @memberof CustomerOrderReviewDto
     */
    images: Array<ImageDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerOrderReviewDto
     */
    anonymous: boolean;
}


export function CustomerOrderReviewDtoFromJSON(json: any): CustomerOrderReviewDto {
    return CustomerOrderReviewDtoFromJSONTyped(json, false);
}

export function CustomerOrderReviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderReviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': CustomerOrderReviewStatusFromJSON(json['status']),
        'customerId': json['customerId'],
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'advantages': json['advantages'],
        'disadvantages': json['disadvantages'],
        'comment': json['comment'],
        'imageIds': json['imageIds'],
        'score': json['score'],
        'customerOrderId': json['customerOrderId'],
        'spuId': json['spuId'],
        'images': ((json['images'] as Array<any>).map(ImageDtoFromJSON)),
        'anonymous': json['anonymous'],
    };
}

export function CustomerOrderReviewDtoToJSON(value?: CustomerOrderReviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': CustomerOrderReviewStatusToJSON(value.status),
        'customerId': value.customerId,
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'advantages': value.advantages,
        'disadvantages': value.disadvantages,
        'comment': value.comment,
        'imageIds': value.imageIds,
        'score': value.score,
        'customerOrderId': value.customerOrderId,
        'spuId': value.spuId,
        'images': ((value.images as Array<any>).map(ImageDtoToJSON)),
        'anonymous': value.anonymous,
    };
}

