/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderPaymentType,
    CustomerOrderPaymentTypeFromJSON,
    CustomerOrderPaymentTypeFromJSONTyped,
    CustomerOrderPaymentTypeToJSON,
} from './CustomerOrderPaymentType';
import {
    OrderCreateV2DeliveryFromMskDto,
    OrderCreateV2DeliveryFromMskDtoFromJSON,
    OrderCreateV2DeliveryFromMskDtoFromJSONTyped,
    OrderCreateV2DeliveryFromMskDtoToJSON,
} from './OrderCreateV2DeliveryFromMskDto';
import {
    OrderCreateV2GoogleAnalyticsDto,
    OrderCreateV2GoogleAnalyticsDtoFromJSON,
    OrderCreateV2GoogleAnalyticsDtoFromJSONTyped,
    OrderCreateV2GoogleAnalyticsDtoToJSON,
} from './OrderCreateV2GoogleAnalyticsDto';
import {
    OrderCreateV2Source,
    OrderCreateV2SourceFromJSON,
    OrderCreateV2SourceFromJSONTyped,
    OrderCreateV2SourceToJSON,
} from './OrderCreateV2Source';
import {
    OrderCreateV2UtmDto,
    OrderCreateV2UtmDtoFromJSON,
    OrderCreateV2UtmDtoFromJSONTyped,
    OrderCreateV2UtmDtoToJSON,
} from './OrderCreateV2UtmDto';
import {
    OrderCreateV2YaMetrikaDto,
    OrderCreateV2YaMetrikaDtoFromJSON,
    OrderCreateV2YaMetrikaDtoFromJSONTyped,
    OrderCreateV2YaMetrikaDtoToJSON,
} from './OrderCreateV2YaMetrikaDto';

/**
 * 
 * @export
 * @interface OrderCreateV2RequestDto
 */
export interface OrderCreateV2RequestDto {
    /**
     * 
     * @type {OrderCreateV2Source}
     * @memberof OrderCreateV2RequestDto
     */
    source?: OrderCreateV2Source;
    /**
     * 
     * @type {CustomerOrderPaymentType}
     * @memberof OrderCreateV2RequestDto
     */
    paymentType?: CustomerOrderPaymentType;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateV2RequestDto
     */
    spu: number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateV2RequestDto
     */
    sku: number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    deliveryToMSK: OrderCreateV2RequestDtoDeliveryToMSKEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    cashback: OrderCreateV2RequestDtoCashbackEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCreateV2RequestDto
     */
    split: boolean;
    /**
     * 
     * @type {OrderCreateV2DeliveryFromMskDto}
     * @memberof OrderCreateV2RequestDto
     */
    deliveryFromMSK: OrderCreateV2DeliveryFromMskDto;
    /**
     * 
     * @type {OrderCreateV2UtmDto}
     * @memberof OrderCreateV2RequestDto
     */
    utm?: OrderCreateV2UtmDto;
    /**
     * 
     * @type {OrderCreateV2YaMetrikaDto}
     * @memberof OrderCreateV2RequestDto
     */
    yaMetrika?: OrderCreateV2YaMetrikaDto;
    /**
     * 
     * @type {OrderCreateV2GoogleAnalyticsDto}
     * @memberof OrderCreateV2RequestDto
     */
    googleAnalytics?: OrderCreateV2GoogleAnalyticsDto;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    postHogId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    roistat?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    collection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateV2RequestDto
     */
    clickId?: string | null;
}

/**
* @export
* @enum {string}
*/
export type OrderCreateV2RequestDtoDeliveryToMSKEnum =
    | 'usual'
    | 'express'
    | 'shop'
/**
* @export
* @enum {string}
*/
export type OrderCreateV2RequestDtoCashbackEnum =
    | 'gain'
    | 'charge'



export function OrderCreateV2RequestDtoFromJSON(json: any): OrderCreateV2RequestDto {
    return OrderCreateV2RequestDtoFromJSONTyped(json, false);
}

export function OrderCreateV2RequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateV2RequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : OrderCreateV2SourceFromJSON(json['source']),
        'paymentType': !exists(json, 'paymentType') ? undefined : CustomerOrderPaymentTypeFromJSON(json['paymentType']),
        'spu': json['spu'],
        'sku': json['sku'],
        'deliveryToMSK': json['deliveryToMSK'],
        'cashback': json['cashback'],
        'split': json['split'],
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoFromJSON(json['deliveryFromMSK']),
        'utm': !exists(json, 'utm') ? undefined : OrderCreateV2UtmDtoFromJSON(json['utm']),
        'yaMetrika': !exists(json, 'yaMetrika') ? undefined : OrderCreateV2YaMetrikaDtoFromJSON(json['yaMetrika']),
        'googleAnalytics': !exists(json, 'googleAnalytics') ? undefined : OrderCreateV2GoogleAnalyticsDtoFromJSON(json['googleAnalytics']),
        'postHogId': !exists(json, 'postHogId') ? undefined : json['postHogId'],
        'roistat': !exists(json, 'roistat') ? undefined : json['roistat'],
        'collection': !exists(json, 'collection') ? undefined : json['collection'],
        'clickId': !exists(json, 'clickId') ? undefined : json['clickId'],
    };
}

export function OrderCreateV2RequestDtoToJSON(value?: OrderCreateV2RequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': OrderCreateV2SourceToJSON(value.source),
        'paymentType': CustomerOrderPaymentTypeToJSON(value.paymentType),
        'spu': value.spu,
        'sku': value.sku,
        'deliveryToMSK': value.deliveryToMSK,
        'cashback': value.cashback,
        'split': value.split,
        'deliveryFromMSK': OrderCreateV2DeliveryFromMskDtoToJSON(value.deliveryFromMSK),
        'utm': OrderCreateV2UtmDtoToJSON(value.utm),
        'yaMetrika': OrderCreateV2YaMetrikaDtoToJSON(value.yaMetrika),
        'googleAnalytics': OrderCreateV2GoogleAnalyticsDtoToJSON(value.googleAnalytics),
        'postHogId': value.postHogId,
        'roistat': value.roistat,
        'collection': value.collection,
        'clickId': value.clickId,
    };
}

