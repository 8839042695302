/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerOrderPaymentStatus,
    CustomerOrderPaymentStatusFromJSON,
    CustomerOrderPaymentStatusFromJSONTyped,
    CustomerOrderPaymentStatusToJSON,
} from './CustomerOrderPaymentStatus';
import {
    PaymentFlowLinkDto,
    PaymentFlowLinkDtoFromJSON,
    PaymentFlowLinkDtoFromJSONTyped,
    PaymentFlowLinkDtoToJSON,
} from './PaymentFlowLinkDto';
import {
    PaymentFlowTransferDto,
    PaymentFlowTransferDtoFromJSON,
    PaymentFlowTransferDtoFromJSONTyped,
    PaymentFlowTransferDtoToJSON,
} from './PaymentFlowTransferDto';
import {
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface PaymentFlowDto
 */
export interface PaymentFlowDto {
    /**
     * 
     * @type {CustomerOrderPaymentStatus}
     * @memberof PaymentFlowDto
     */
    status: CustomerOrderPaymentStatus;
    /**
     * 
     * @type {PaymentType}
     * @memberof PaymentFlowDto
     */
    method: PaymentType;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowDto
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowDto
     */
    amount: string;
    /**
     * 
     * @type {PaymentFlowTransferDto}
     * @memberof PaymentFlowDto
     */
    transfer?: PaymentFlowTransferDto;
    /**
     * 
     * @type {PaymentFlowLinkDto}
     * @memberof PaymentFlowDto
     */
    sbp?: PaymentFlowLinkDto;
    /**
     * 
     * @type {PaymentFlowLinkDto}
     * @memberof PaymentFlowDto
     */
    card?: PaymentFlowLinkDto;
    /**
     * 
     * @type {PaymentFlowLinkDto}
     * @memberof PaymentFlowDto
     */
    external?: PaymentFlowLinkDto;
    /**
     * 
     * @type {PaymentFlowLinkDto}
     * @memberof PaymentFlowDto
     */
    nspk?: PaymentFlowLinkDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentFlowDto
     */
    receiptImageId: string | null;
}


export function PaymentFlowDtoFromJSON(json: any): PaymentFlowDto {
    return PaymentFlowDtoFromJSONTyped(json, false);
}

export function PaymentFlowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentFlowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': CustomerOrderPaymentStatusFromJSON(json['status']),
        'method': PaymentTypeFromJSON(json['method']),
        'id': json['id'],
        'orderId': json['orderId'],
        'amount': json['amount'],
        'transfer': !exists(json, 'transfer') ? undefined : PaymentFlowTransferDtoFromJSON(json['transfer']),
        'sbp': !exists(json, 'sbp') ? undefined : PaymentFlowLinkDtoFromJSON(json['sbp']),
        'card': !exists(json, 'card') ? undefined : PaymentFlowLinkDtoFromJSON(json['card']),
        'external': !exists(json, 'external') ? undefined : PaymentFlowLinkDtoFromJSON(json['external']),
        'nspk': !exists(json, 'nspk') ? undefined : PaymentFlowLinkDtoFromJSON(json['nspk']),
        'receiptImageId': json['receiptImageId'],
    };
}

export function PaymentFlowDtoToJSON(value?: PaymentFlowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': CustomerOrderPaymentStatusToJSON(value.status),
        'method': PaymentTypeToJSON(value.method),
        'id': value.id,
        'orderId': value.orderId,
        'amount': value.amount,
        'transfer': PaymentFlowTransferDtoToJSON(value.transfer),
        'sbp': PaymentFlowLinkDtoToJSON(value.sbp),
        'card': PaymentFlowLinkDtoToJSON(value.card),
        'external': PaymentFlowLinkDtoToJSON(value.external),
        'nspk': PaymentFlowLinkDtoToJSON(value.nspk),
        'receiptImageId': value.receiptImageId,
    };
}

