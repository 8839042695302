/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CatalogProductColorDto
 */
export interface CatalogProductColorDto {
  /**
   *
   * @type {string}
   * @memberof CatalogProductColorDto
   */
  main?: string
  /**
   *
   * @type {string}
   * @memberof CatalogProductColorDto
   */
  additional?: string
}

export function CatalogProductColorDtoFromJSON(json: any): CatalogProductColorDto {
  return CatalogProductColorDtoFromJSONTyped(json, false)
}

export function CatalogProductColorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductColorDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    main: !exists(json, "main") ? undefined : json["main"],
    additional: !exists(json, "additional") ? undefined : json["additional"],
  }
}

export function CatalogProductColorDtoToJSON(value?: CatalogProductColorDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main: value.main,
    additional: value.additional,
  }
}
