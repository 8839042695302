/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogProductBasicPropertyDefinitionEnum,
  CatalogProductBasicPropertyDefinitionEnumFromJSON,
  CatalogProductBasicPropertyDefinitionEnumFromJSONTyped,
  CatalogProductBasicPropertyDefinitionEnumToJSON,
} from "./CatalogProductBasicPropertyDefinitionEnum"

/**
 *
 * @export
 * @interface CatalogProductBasicPropertiesDto
 */
export interface CatalogProductBasicPropertiesDto {
  /**
   *
   * @type {CatalogProductBasicPropertyDefinitionEnum}
   * @memberof CatalogProductBasicPropertiesDto
   */
  definitionId?: CatalogProductBasicPropertyDefinitionEnum
  /**
   *
   * @type {string}
   * @memberof CatalogProductBasicPropertiesDto
   */
  key: string
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductBasicPropertiesDto
   */
  value: Array<string>
}

export function CatalogProductBasicPropertiesDtoFromJSON(json: any): CatalogProductBasicPropertiesDto {
  return CatalogProductBasicPropertiesDtoFromJSONTyped(json, false)
}

export function CatalogProductBasicPropertiesDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CatalogProductBasicPropertiesDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    definitionId: !exists(json, "definitionId")
      ? undefined
      : CatalogProductBasicPropertyDefinitionEnumFromJSON(json["definitionId"]),
    key: json["key"],
    value: json["value"],
  }
}

export function CatalogProductBasicPropertiesDtoToJSON(value?: CatalogProductBasicPropertiesDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    definitionId: CatalogProductBasicPropertyDefinitionEnumToJSON(value.definitionId),
    key: value.key,
    value: value.value,
  }
}
