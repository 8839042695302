/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"
import {
  CatalogProductCategory,
  CatalogProductCategoryFromJSON,
  CatalogProductCategoryToJSON,
  CatalogProductDto,
  CatalogProductDtoFromJSON,
  CatalogProductDtoToJSON,
  CatalogProductFit,
  CatalogProductFitFromJSON,
  CatalogProductFitToJSON,
  CatalogProductListDto,
  CatalogProductListDtoFromJSON,
  CatalogProductListDtoToJSON,
  CatalogProductPropertiesDto,
  CatalogProductPropertiesDtoFromJSON,
  CatalogProductPropertiesDtoToJSON,
  CatalogProductShortDto,
  CatalogProductShortDtoFromJSON,
  CatalogProductShortDtoToJSON,
  CatalogProductSizeType,
  CatalogProductSizeTypeFromJSON,
  CatalogProductSizeTypeToJSON,
  CatalogProductSort,
  CatalogProductSortFromJSON,
  CatalogProductSortToJSON,
  CategoryTree,
  CategoryTreeFromJSON,
  CategoryTreeToJSON,
  SearchResultFilters,
  SearchResultFiltersFromJSON,
  SearchResultFiltersToJSON,
} from "../models"

export interface CatalogProductRequest {
  id: number
}

export interface CatalogProductCommodityRequest {
  id: number
}

export interface CatalogProductFiltersRequest {
  category?: CatalogProductCategory
  sizeType?: CatalogProductSizeType
  sort?: CatalogProductSort
  fit?: Array<CatalogProductFit>
  search?: string
  brands?: Array<string>
  sizeValue?: string
  priceFrom?: number
  priceTo?: number
  categorySlug?: string
  fromAvailability?: boolean
  fromAvailabilityDiscount?: boolean
  description?: boolean
}

export interface CatalogProductListRequest {
  category?: CatalogProductCategory
  sizeType?: CatalogProductSizeType
  sort?: CatalogProductSort
  fit?: Array<CatalogProductFit>
  search?: string
  brands?: Array<string>
  sizeValue?: string
  priceFrom?: number
  priceTo?: number
  categorySlug?: string
  fromAvailability?: boolean
  fromAvailabilityDiscount?: boolean
  description?: boolean
  page?: number
  perPage?: number
}

export interface CatalogProductPropertiesRequest {
  id: number
}

export interface CatalogProductsByIdsRequest {
  ids: Array<number>
  sizeType?: CatalogProductSizeType
  fromAvailability?: boolean
  fromAvailabilityDiscount?: boolean
  sizeValue?: string
}

export interface CatalogProductsBySkuIdsRequest {
  ids: Array<number>
  sizeType?: CatalogProductSizeType
  fromAvailability?: boolean
  fromAvailabilityDiscount?: boolean
  sizeValue?: string
}

/**
 *
 */
export class CatalogApi extends runtime.BaseAPI {
  /**
   */
  protected catalogCategoriesRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/categories`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogCategoriesFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<object>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   */
  protected catalogCategoriesRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> => {
    const context = this.catalogCategoriesRequestOpts()
    return this.catalogCategoriesFetch(context, initOverrides)
  }

  /**
   */
  catalogCategories = async (initOverrides?: RequestInit): Promise<object> => {
    const response = await this.catalogCategoriesRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogCategories = (() => {
    const key = () => this.catalogCategoriesRequestOpts()
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogCategoriesFetch(context))
    const fn = (config?: SWRConfiguration<object>): SWRResponse<object> => {
      return useSWR<object>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogCategoryTreeRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/category-tree`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogCategoryTreeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CategoryTree>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => CategoryTreeFromJSON(jsonValue))
  }

  /**
   */
  protected catalogCategoryTreeRaw = async (
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CategoryTree>> => {
    const context = this.catalogCategoryTreeRequestOpts()
    return this.catalogCategoryTreeFetch(context, initOverrides)
  }

  /**
   */
  catalogCategoryTree = async (initOverrides?: RequestInit): Promise<CategoryTree> => {
    const response = await this.catalogCategoryTreeRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogCategoryTree = (() => {
    const key = () => this.catalogCategoryTreeRequestOpts()
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogCategoryTreeFetch(context))
    const fn = (config?: SWRConfiguration<CategoryTree>): SWRResponse<CategoryTree> => {
      return useSWR<CategoryTree>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogPopularShoesRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/popular-shoes`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogPopularShoesFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatalogProductShortDtoFromJSON))
  }

  /**
   */
  protected catalogPopularShoesRaw = async (
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const context = this.catalogPopularShoesRequestOpts()
    return this.catalogPopularShoesFetch(context, initOverrides)
  }

  /**
   */
  catalogPopularShoes = async (initOverrides?: RequestInit): Promise<Array<CatalogProductShortDto>> => {
    const response = await this.catalogPopularShoesRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogPopularShoes = (() => {
    const key = () => this.catalogPopularShoesRequestOpts()
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogPopularShoesFetch(context))
    const fn = (
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ): SWRResponse<Array<CatalogProductShortDto>> => {
      return useSWR<Array<CatalogProductShortDto>>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductRequestOpts = (requestParameters: CatalogProductRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling catalogProduct.",
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductDto>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => CatalogProductDtoFromJSON(jsonValue))
  }

  /**
   */
  protected catalogProductRaw = async (
    requestParameters: CatalogProductRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductDto>> => {
    const context = this.catalogProductRequestOpts(requestParameters)
    return this.catalogProductFetch(context, initOverrides)
  }

  /**
   */
  catalogProduct = async (
    requestParameters: CatalogProductRequest,
    initOverrides?: RequestInit,
  ): Promise<CatalogProductDto> => {
    const response = await this.catalogProductRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProduct = (() => {
    const key = (id: number) => this.catalogProductRequestOpts({ id: id })
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductFetch(context))
    const fn = (id: number, config?: SWRConfiguration<CatalogProductDto>): SWRResponse<CatalogProductDto> => {
      return useSWR<CatalogProductDto>(() => key(id), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductCommodityRequestOpts = (
    requestParameters: CatalogProductCommodityRequest,
  ): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling catalogProductCommodity.",
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product/{id}/commodity`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductCommodityFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<object>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   */
  protected catalogProductCommodityRaw = async (
    requestParameters: CatalogProductCommodityRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<object>> => {
    const context = this.catalogProductCommodityRequestOpts(requestParameters)
    return this.catalogProductCommodityFetch(context, initOverrides)
  }

  /**
   */
  catalogProductCommodity = async (
    requestParameters: CatalogProductCommodityRequest,
    initOverrides?: RequestInit,
  ): Promise<object> => {
    const response = await this.catalogProductCommodityRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductCommodity = (() => {
    const key = (id: number) => this.catalogProductCommodityRequestOpts({ id: id })
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductCommodityFetch(context))
    const fn = (id: number, config?: SWRConfiguration<object>): SWRResponse<object> => {
      return useSWR<object>(() => key(id), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductFiltersRequestOpts = (
    requestParameters: CatalogProductFiltersRequest,
  ): runtime.RequestOpts => {
    const queryParameters: any = {}

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category
    }

    if (requestParameters.sizeType !== undefined) {
      queryParameters["sizeType"] = requestParameters.sizeType
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.fit) {
      queryParameters["fit"] = requestParameters.fit
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search
    }

    if (requestParameters.brands) {
      queryParameters["brands"] = requestParameters.brands
    }

    if (requestParameters.sizeValue !== undefined) {
      queryParameters["sizeValue"] = requestParameters.sizeValue
    }

    if (requestParameters.priceFrom !== undefined) {
      queryParameters["priceFrom"] = requestParameters.priceFrom
    }

    if (requestParameters.priceTo !== undefined) {
      queryParameters["priceTo"] = requestParameters.priceTo
    }

    if (requestParameters.categorySlug !== undefined) {
      queryParameters["categorySlug"] = requestParameters.categorySlug
    }

    if (requestParameters.fromAvailability !== undefined) {
      queryParameters["fromAvailability"] = requestParameters.fromAvailability
    }

    if (requestParameters.fromAvailabilityDiscount !== undefined) {
      queryParameters["fromAvailabilityDiscount"] = requestParameters.fromAvailabilityDiscount
    }

    if (requestParameters.description !== undefined) {
      queryParameters["description"] = requestParameters.description
    }

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product-filters`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductFiltersFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SearchResultFilters>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultFiltersFromJSON(jsonValue))
  }

  /**
   */
  protected catalogProductFiltersRaw = async (
    requestParameters: CatalogProductFiltersRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SearchResultFilters>> => {
    const context = this.catalogProductFiltersRequestOpts(requestParameters)
    return this.catalogProductFiltersFetch(context, initOverrides)
  }

  /**
   */
  catalogProductFilters = async (
    requestParameters: CatalogProductFiltersRequest = {},
    initOverrides?: RequestInit,
  ): Promise<SearchResultFilters> => {
    const response = await this.catalogProductFiltersRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductFilters = (() => {
    const key = (
      requestParameters: CatalogProductFiltersRequest = {},
      config?: SWRConfiguration<SearchResultFilters>,
    ) => this.catalogProductFiltersRequestOpts(requestParameters)
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductFiltersFetch(context))
    const fn = (
      requestParameters: CatalogProductFiltersRequest = {},
      config?: SWRConfiguration<SearchResultFilters>,
    ): SWRResponse<SearchResultFilters> => {
      return useSWR<SearchResultFilters>(() => key(requestParameters), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductListRequestOpts = (requestParameters: CatalogProductListRequest): runtime.RequestOpts => {
    const queryParameters: any = {}

    if (requestParameters.category !== undefined) {
      queryParameters["category"] = requestParameters.category
    }

    if (requestParameters.sizeType !== undefined) {
      queryParameters["sizeType"] = requestParameters.sizeType
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.fit) {
      queryParameters["fit"] = requestParameters.fit
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search
    }

    if (requestParameters.brands) {
      queryParameters["brands"] = requestParameters.brands
    }

    if (requestParameters.sizeValue !== undefined) {
      queryParameters["sizeValue"] = requestParameters.sizeValue
    }

    if (requestParameters.priceFrom !== undefined) {
      queryParameters["priceFrom"] = requestParameters.priceFrom
    }

    if (requestParameters.priceTo !== undefined) {
      queryParameters["priceTo"] = requestParameters.priceTo
    }

    if (requestParameters.categorySlug !== undefined) {
      queryParameters["categorySlug"] = requestParameters.categorySlug
    }

    if (requestParameters.fromAvailability !== undefined) {
      queryParameters["fromAvailability"] = requestParameters.fromAvailability
    }

    if (requestParameters.fromAvailabilityDiscount !== undefined) {
      queryParameters["fromAvailabilityDiscount"] = requestParameters.fromAvailabilityDiscount
    }

    if (requestParameters.description !== undefined) {
      queryParameters["description"] = requestParameters.description
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["perPage"] = requestParameters.perPage
    }

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductListDto>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => CatalogProductListDtoFromJSON(jsonValue))
  }

  /**
   */
  protected catalogProductListRaw = async (
    requestParameters: CatalogProductListRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductListDto>> => {
    const context = this.catalogProductListRequestOpts(requestParameters)
    return this.catalogProductListFetch(context, initOverrides)
  }

  /**
   */
  catalogProductList = async (
    requestParameters: CatalogProductListRequest = {},
    initOverrides?: RequestInit,
  ): Promise<CatalogProductListDto> => {
    const response = await this.catalogProductListRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductList = (() => {
    const key = (requestParameters: CatalogProductListRequest = {}, config?: SWRConfiguration<CatalogProductListDto>) =>
      this.catalogProductListRequestOpts(requestParameters)
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductListFetch(context))
    const fn = (
      requestParameters: CatalogProductListRequest = {},
      config?: SWRConfiguration<CatalogProductListDto>,
    ): SWRResponse<CatalogProductListDto> => {
      return useSWR<CatalogProductListDto>(() => key(requestParameters), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductPropertiesRequestOpts = (
    requestParameters: CatalogProductPropertiesRequest,
  ): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling catalogProductProperties.",
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product/{id}/properties`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductPropertiesFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductPropertiesDto>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => CatalogProductPropertiesDtoFromJSON(jsonValue))
  }

  /**
   */
  protected catalogProductPropertiesRaw = async (
    requestParameters: CatalogProductPropertiesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CatalogProductPropertiesDto>> => {
    const context = this.catalogProductPropertiesRequestOpts(requestParameters)
    return this.catalogProductPropertiesFetch(context, initOverrides)
  }

  /**
   */
  catalogProductProperties = async (
    requestParameters: CatalogProductPropertiesRequest,
    initOverrides?: RequestInit,
  ): Promise<CatalogProductPropertiesDto> => {
    const response = await this.catalogProductPropertiesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductProperties = (() => {
    const key = (id: number) => this.catalogProductPropertiesRequestOpts({ id: id })
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductPropertiesFetch(context))
    const fn = (
      id: number,
      config?: SWRConfiguration<CatalogProductPropertiesDto>,
    ): SWRResponse<CatalogProductPropertiesDto> => {
      return useSWR<CatalogProductPropertiesDto>(() => key(id), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductSlugsForSitemapRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/product-slugs-for-sitemap`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductSlugsForSitemapFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  protected catalogProductSlugsForSitemapRaw = async (
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.catalogProductSlugsForSitemapRequestOpts()
    return this.catalogProductSlugsForSitemapFetch(context, initOverrides)
  }

  /**
   */
  catalogProductSlugsForSitemap = async (initOverrides?: RequestInit): Promise<void> => {
    await this.catalogProductSlugsForSitemapRaw(initOverrides)
  }

  /**
   */
  protected catalogProductsByIdsRequestOpts = (requestParameters: CatalogProductsByIdsRequest): runtime.RequestOpts => {
    if (requestParameters.ids === null || requestParameters.ids === undefined) {
      throw new runtime.RequiredError(
        "ids",
        "Required parameter requestParameters.ids was null or undefined when calling catalogProductsByIds.",
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sizeType !== undefined) {
      queryParameters["sizeType"] = requestParameters.sizeType
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids
    }

    if (requestParameters.fromAvailability !== undefined) {
      queryParameters["fromAvailability"] = requestParameters.fromAvailability
    }

    if (requestParameters.fromAvailabilityDiscount !== undefined) {
      queryParameters["fromAvailabilityDiscount"] = requestParameters.fromAvailabilityDiscount
    }

    if (requestParameters.sizeValue !== undefined) {
      queryParameters["sizeValue"] = requestParameters.sizeValue
    }

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/products-by-ids`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductsByIdsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatalogProductShortDtoFromJSON))
  }

  /**
   */
  protected catalogProductsByIdsRaw = async (
    requestParameters: CatalogProductsByIdsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const context = this.catalogProductsByIdsRequestOpts(requestParameters)
    return this.catalogProductsByIdsFetch(context, initOverrides)
  }

  /**
   */
  catalogProductsByIds = async (
    requestParameters: CatalogProductsByIdsRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<CatalogProductShortDto>> => {
    const response = await this.catalogProductsByIdsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductsByIds = (() => {
    const key = (
      requestParameters: CatalogProductsByIdsRequest,
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ) => this.catalogProductsByIdsRequestOpts(requestParameters)
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductsByIdsFetch(context))
    const fn = (
      requestParameters: CatalogProductsByIdsRequest,
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ): SWRResponse<Array<CatalogProductShortDto>> => {
      return useSWR<Array<CatalogProductShortDto>>(() => key(requestParameters), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogProductsBySkuIdsRequestOpts = (
    requestParameters: CatalogProductsBySkuIdsRequest,
  ): runtime.RequestOpts => {
    if (requestParameters.ids === null || requestParameters.ids === undefined) {
      throw new runtime.RequiredError(
        "ids",
        "Required parameter requestParameters.ids was null or undefined when calling catalogProductsBySkuIds.",
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sizeType !== undefined) {
      queryParameters["sizeType"] = requestParameters.sizeType
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids
    }

    if (requestParameters.fromAvailability !== undefined) {
      queryParameters["fromAvailability"] = requestParameters.fromAvailability
    }

    if (requestParameters.fromAvailabilityDiscount !== undefined) {
      queryParameters["fromAvailabilityDiscount"] = requestParameters.fromAvailabilityDiscount
    }

    if (requestParameters.sizeValue !== undefined) {
      queryParameters["sizeValue"] = requestParameters.sizeValue
    }

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/products-by-sku-ids`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogProductsBySkuIdsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatalogProductShortDtoFromJSON))
  }

  /**
   */
  protected catalogProductsBySkuIdsRaw = async (
    requestParameters: CatalogProductsBySkuIdsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const context = this.catalogProductsBySkuIdsRequestOpts(requestParameters)
    return this.catalogProductsBySkuIdsFetch(context, initOverrides)
  }

  /**
   */
  catalogProductsBySkuIds = async (
    requestParameters: CatalogProductsBySkuIdsRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<CatalogProductShortDto>> => {
    const response = await this.catalogProductsBySkuIdsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogProductsBySkuIds = (() => {
    const key = (
      requestParameters: CatalogProductsBySkuIdsRequest,
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ) => this.catalogProductsBySkuIdsRequestOpts(requestParameters)
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogProductsBySkuIdsFetch(context))
    const fn = (
      requestParameters: CatalogProductsBySkuIdsRequest,
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ): SWRResponse<Array<CatalogProductShortDto>> => {
      return useSWR<Array<CatalogProductShortDto>>(() => key(requestParameters), fetcher, config)
    }
    fn.key = key
    return fn
  })()

  /**
   */
  protected catalogShoesInShowRoomRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    return {
      path: `/api/catalog/shoes-in-showroom`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    }
  }

  /**
   */
  protected catalogShoesInShowRoomFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const response = await this.request(context, initOverrides)

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatalogProductShortDtoFromJSON))
  }

  /**
   */
  protected catalogShoesInShowRoomRaw = async (
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<CatalogProductShortDto>>> => {
    const context = this.catalogShoesInShowRoomRequestOpts()
    return this.catalogShoesInShowRoomFetch(context, initOverrides)
  }

  /**
   */
  catalogShoesInShowRoom = async (initOverrides?: RequestInit): Promise<Array<CatalogProductShortDto>> => {
    const response = await this.catalogShoesInShowRoomRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  useCatalogShoesInShowRoom = (() => {
    const key = () => this.catalogShoesInShowRoomRequestOpts()
    const fetcher = (context: runtime.RequestOpts) => this.swrFetch(this.catalogShoesInShowRoomFetch(context))
    const fn = (
      config?: SWRConfiguration<Array<CatalogProductShortDto>>,
    ): SWRResponse<Array<CatalogProductShortDto>> => {
      return useSWR<Array<CatalogProductShortDto>>(() => key(), fetcher, config)
    }
    fn.key = key
    return fn
  })()
}
