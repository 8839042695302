/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Catalog
 * Poizon Catalog
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  CatalogPriceV2Dto,
  CatalogPriceV2DtoFromJSON,
  CatalogPriceV2DtoFromJSONTyped,
  CatalogPriceV2DtoToJSON,
} from "./CatalogPriceV2Dto"
import {
  CatalogProductCategoryDto,
  CatalogProductCategoryDtoFromJSON,
  CatalogProductCategoryDtoFromJSONTyped,
  CatalogProductCategoryDtoToJSON,
} from "./CatalogProductCategoryDto"
import {
  CatalogProductMetadataDto,
  CatalogProductMetadataDtoFromJSON,
  CatalogProductMetadataDtoFromJSONTyped,
  CatalogProductMetadataDtoToJSON,
} from "./CatalogProductMetadataDto"
import {
  CatalogProductPriceAvailability,
  CatalogProductPriceAvailabilityFromJSON,
  CatalogProductPriceAvailabilityFromJSONTyped,
  CatalogProductPriceAvailabilityToJSON,
} from "./CatalogProductPriceAvailability"
import {
  CatalogProductSkuDeliveryTimeDto,
  CatalogProductSkuDeliveryTimeDtoFromJSON,
  CatalogProductSkuDeliveryTimeDtoFromJSONTyped,
  CatalogProductSkuDeliveryTimeDtoToJSON,
} from "./CatalogProductSkuDeliveryTimeDto"
import {
  CatalogProductSplitDto,
  CatalogProductSplitDtoFromJSON,
  CatalogProductSplitDtoFromJSONTyped,
  CatalogProductSplitDtoToJSON,
} from "./CatalogProductSplitDto"

/**
 *
 * @export
 * @interface CatalogProductShortDto
 */
export interface CatalogProductShortDto {
  /**
   *
   * @type {CatalogProductPriceAvailability}
   * @memberof CatalogProductShortDto
   */
  availability: CatalogProductPriceAvailability
  /**
   *
   * @type {string}
   * @memberof CatalogProductShortDto
   */
  fit?: CatalogProductShortDtoFitEnum
  /**
   *
   * @type {number}
   * @memberof CatalogProductShortDto
   */
  spuId: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductShortDto
   */
  slug: string
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductShortDto
   */
  images: Array<string>
  /**
   *
   * @type {string}
   * @memberof CatalogProductShortDto
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CatalogProductShortDto
   */
  price: number
  /**
   *
   * @type {string}
   * @memberof CatalogProductShortDto
   */
  brand: string
  /**
   *
   * @type {CatalogProductSplitDto}
   * @memberof CatalogProductShortDto
   */
  split?: CatalogProductSplitDto
  /**
   *
   * @type {number}
   * @memberof CatalogProductShortDto
   */
  skuId?: number
  /**
   *
   * @type {CatalogProductCategoryDto}
   * @memberof CatalogProductShortDto
   */
  category: CatalogProductCategoryDto
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductShortDto
   */
  fromAvailability: boolean
  /**
   *
   * @type {CatalogPriceV2Dto}
   * @memberof CatalogProductShortDto
   */
  priceV2: CatalogPriceV2Dto
  /**
   *
   * @type {number}
   * @memberof CatalogProductShortDto
   */
  maxPrice: number
  /**
   *
   * @type {CatalogProductMetadataDto}
   * @memberof CatalogProductShortDto
   */
  metadata?: CatalogProductMetadataDto
  /**
   *
   * @type {CatalogProductSkuDeliveryTimeDto}
   * @memberof CatalogProductShortDto
   */
  deliveryTime?: CatalogProductSkuDeliveryTimeDto
}

/**
 * @export
 * @enum {string}
 */
export type CatalogProductShortDtoFitEnum =
  | "UNISEX"
  | "MALE"
  | "FEMALE"
  | "CHILD"
  | "BABY"
  | "MIDDLE_CHILD"
  | "ELDEST_CHILD"

export function CatalogProductShortDtoFromJSON(json: any): CatalogProductShortDto {
  return CatalogProductShortDtoFromJSONTyped(json, false)
}

export function CatalogProductShortDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogProductShortDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    availability: CatalogProductPriceAvailabilityFromJSON(json["availability"]),
    fit: !exists(json, "fit") ? undefined : json["fit"],
    spuId: json["spuId"],
    slug: json["slug"],
    images: json["images"],
    name: json["name"],
    price: json["price"],
    brand: json["brand"],
    split: !exists(json, "split") ? undefined : CatalogProductSplitDtoFromJSON(json["split"]),
    skuId: !exists(json, "skuId") ? undefined : json["skuId"],
    category: CatalogProductCategoryDtoFromJSON(json["category"]),
    fromAvailability: json["fromAvailability"],
    priceV2: CatalogPriceV2DtoFromJSON(json["priceV2"]),
    maxPrice: json["maxPrice"],
    metadata: !exists(json, "metadata") ? undefined : CatalogProductMetadataDtoFromJSON(json["metadata"]),
    deliveryTime: !exists(json, "deliveryTime")
      ? undefined
      : CatalogProductSkuDeliveryTimeDtoFromJSON(json["deliveryTime"]),
  }
}

export function CatalogProductShortDtoToJSON(value?: CatalogProductShortDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    availability: CatalogProductPriceAvailabilityToJSON(value.availability),
    fit: value.fit,
    spuId: value.spuId,
    slug: value.slug,
    images: value.images,
    name: value.name,
    price: value.price,
    brand: value.brand,
    split: CatalogProductSplitDtoToJSON(value.split),
    skuId: value.skuId,
    category: CatalogProductCategoryDtoToJSON(value.category),
    fromAvailability: value.fromAvailability,
    priceV2: CatalogPriceV2DtoToJSON(value.priceV2),
    maxPrice: value.maxPrice,
    metadata: CatalogProductMetadataDtoToJSON(value.metadata),
    deliveryTime: CatalogProductSkuDeliveryTimeDtoToJSON(value.deliveryTime),
  }
}
